import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'date' ];
  static values = {
    date: String,
    translations: Object,
  };

  dateTargetConnected() {
    const translations = this.translationsValue;
    const countDownDate = new Date(this.dateValue + 'Z').getTime();
    const target = this.dateTarget;
    const x = setInterval(function() {
      const now = new Date().getTime();
      const distance = countDownDate - now;

      const years = Math.floor(distance / (1000 * 60 * 60 * 24 * 365));
      const months = Math.floor(distance / (1000 * 60 * 60 * 24 * 30));
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (years > 0 || months > 0 || days > 0) {
        clearInterval(x);
        return;
      }

      const intervals = [
        {value: years, unit: translations.year},
        {value: months, unit: translations.month},
        {value: days, unit: translations.day},
        {value: hours, unit: translations.hour},
        {value: minutes, unit: translations.minute},
        {value: seconds, unit: translations.second},
      ].filter((interval) => interval.value > 0).slice(0, 2);

      if (intervals.length === 0) {
        target.innerHTML = '<1' + translations.second;
        clearInterval(x);
        return;
      }

      target.innerHTML = intervals.map((interval) => `${interval.value}${interval.unit}`).join(' ');
    }, 1000);
  }
}
