import { Controller } from '@hotwired/stimulus';
import gtmPush from '../gtm';

export default class extends Controller {
  connect() {
    const params = JSON.parse(this.data.get('dataValue'));
    if (!(Array.isArray(params) && params.length === 3)) {
      return;
    }

    gtmPush(params);
  }
}
