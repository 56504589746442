import { Controller } from '@hotwired/stimulus';
import gtmPush from '../gtm';

export default class extends Controller {
  static targets = [
    'agreement',
    'providers',
    'frontShop',
    'submits',
  ];

  connect() {
    this.onChange();
  }

  onChange() {
    if (this.agreementTarget.checked) {
      this.agreementTarget.classList.add('checked');
    } else {
      this.agreementTarget.classList.remove('checked');
    }

    if (this.providersTargets.some((provider) => provider.checked)) {
      this.providersTargets.forEach((provider) => {
        provider.classList.add('checked');
      });
    } else {
      this.providersTargets.forEach((provider) => {
        provider.classList.remove('checked');
      });
    }

    if (Number(this.frontShopTarget.value) === 1 &&
      this.agreementTarget.checked &&
      this.providersTargets.some((provider) => provider.checked)
    ) {
      this.submitsTargets.forEach((submit) => {
        submit.removeAttribute('disabled');
      });

      this.element.classList.remove('payment-form-highlight');
    } else {
      this.submitsTargets.forEach((submit) => {
        submit.setAttribute('disabled', '');
      });

      this.element.classList.add('payment-form-highlight');
    }
  }

  submit(event) {
    gtmPush(event.params.data);
  }
}
