import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    window.onload = () => {
      // it is commented because unused and:
      // turbo/stimulus implementation is a little broken in one case of parallel loading flows:
      // https://sentry.kube.shared.pxfd.tech/share/issue/ebf6b65bc158491e8d0fae76ade62baf/
      // @ts-expect-error out of scope
      // window.onPaymentDialogSucceeded(() => {
      /* do something on payment success*/
      // });
      window.onCloseAfterSuccessPayment = function() {
        window.Turbo.visit(window.location.href, { action: 'replace' });
      };
    };
  }

  openPaymentDialog = async (event) => {
    const { /* packid, */ intenturl: intentUrl, locale } = event.params;
    // @ts-expect-error out of scope
    if (window.openPaymentDialogModal === undefined) {
      throw new Error('PaymentDialog is not defined. React script is probably not loaded');
    }

    // @ts-expect-error out of scope
    window.openPaymentDialogModal(locale);

    let result = await fetch(intentUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (result.ok) {
      let data = await result.json();
      // @ts-expect-error out of scope
      window.setPaymentDialogIntentId(data.payment_intent_id);

      return;
    }

    // @ts-expect-error out of scope
    window.setPaymentDialogIntentIdCreateFailed();
  };
}
