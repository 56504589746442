import { Controller } from '@hotwired/stimulus';
import Modal from 'bootstrap/js/dist/modal';
import gtmPush from '../gtm';

export default class extends Controller {
  static targets = [
    'modal',
  ];

  static values = {
    frameId: String,
    keyboard: Boolean,
    backdrop: String,
  };

  modal = null;

  async openModal(clickEvent)
  {
    // reloads turbo frame content on each modal opening
    let frame = document.getElementById(this.frameIdValue);
    frame.setAttribute('src', frame.getAttribute('src'));

    let options = {};
    if (this.hasKeyboardValue) {
      options.keyboard = this.keyboardValue;
    }
    if (this.hasBackdropValue) {
      options.backdrop = this.backdropValue;
    }

    this.modal = new Modal(this.modalTarget, options);

    if (typeof clickEvent?.params?.gtmPushOpen === 'object') {
      this.modalTarget.addEventListener('show.bs.modal', () => {
        gtmPush(clickEvent.params.gtmPushOpen);
      });
    }
    if (typeof clickEvent?.params?.gtmPushClose === 'object') {
      this.modalTarget.addEventListener('hide.bs.modal', () => {
        gtmPush(clickEvent.params.gtmPushClose);
      });
    }

    this.modal.show();
  }
}
