import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  pollCount = 0;
  refreshTimer = null;

  static values = {
    numberOfRetries: Number,
    refreshInterval: Number,
    pollingUrl: String,
    pollingTimeoutUrl: String
  };

  connect()
  {
    if (!this.numberOfRetriesValue) {
      console.error(
        '[stimulus-turbo-frame-reload] You need to provide numberOfRetries.'
      );

      return;
    }

    if (!this.hasRefreshIntervalValue) {
      console.error(
        '[stimulus-turbo-frame-reload] You need to provide refreshInterval.'
      );

      return;
    }

    if (!this.element.hasAttribute('pollingUrl') && !this.hasPollingUrlValue) {
      console.error(
        '[stimulus-turbo-frame-reload] You need to pass an url to fetch the remote content.'
      );

      return;
    }

    if (!this.element.hasAttribute('pollingTimeoutUrl') && !this.hasPollingTimeoutUrlValue) {
      console.error(
        '[stimulus-turbo-frame-reload] You need to pass where is redirected when the polling is timeout.'
      );

      return;
    }


    this.startRefreshing();
  }

  disconnect()
  {
    this.stopRefreshing();
  }

  startRefreshing()
  {

    this.refreshTimer = setInterval(() => {
      this.pollCount++;
      this.fetch();
    }, this.refreshIntervalValue);
  }

  stopRefreshing()
  {
    this.refreshTimer && clearInterval(this.refreshTimer);
  }

  fetch()
  {
    if (this.hasNumberOfRetriesValue && this.pollCount > this.numberOfRetriesValue) {
      this.stopRefreshing();
      // redirect to error page
      window.location.href = this.pollingTimeoutUrlValue;

      return;
    }

    if (this.element.hasAttribute('src')) {
      this.element.reload();

      return;
    }

    this.element.setAttribute('src', this.pollingUrlValue);
  }
}
